import React from "react"

import { PageLayout } from "../components/layouts"
import SEO from "../components/seo"

import TextBlock from "../components/textBlock"
import SingleColumnBlock from "../components/singleColumnBlock"
import Blockquote from "../components/blockquote"

import ActiveItemGallery from "@components/ActiveItemGallery"

import {
  ContactForm,
  FormWrapper,
  RequestMoreInfo,
  RequestQuote,
} from "@components/Forms"

import {
  RequestMoreInfoButton,
  ContactUsTodayButton,
  RequestAQuoteButton,
} from "../components/buttons"

const galleryItems = [
  {
    key: 1,
    title: "Westgate Resort",
    images: ["/images/projects/westgate-resorts-project1.jpg"],
  },
  {
    key: 2,
    title: "Westgate Resort",
    images: ["/images/projects/westgate-resorts-project2.jpg"],
  },
  {
    key: 3,
    title: "Westgate Resort",
    images: ["/images/projects/westgate-resorts-project3.jpg"],
  },
].map(m => ({
  ...m,
  listItems: [
    {
      title: "Project",
      value: "Westgate Resorts HQ, Orlando, FL",
    },
    {
      title: "Glazing Contractor",
      value:
        'Lloyds Glass',
    },
    {
      title: "Product Details:",
      value: "1. Low iron laminated glass with transparent, blue-tinted interlayer. 2. Low iron laminated glass with an opaque white interlayer",
    },
  ],
}))

const LaminatedGlassOrlandoFlWestgateResorts = () => {
  return (
    <PageLayout>
      <SEO
        title="Laminated Glass Orlando FL | Westgate Resorts | ArtVue Glass"
        ogDescription="View our custom project at Westgate Resorts. Call us today to see how we can help with custom decorative architectural glass!"
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h1>Westgate Resorts, Orlando, FL</h1>
            <p>
              Westgate Resorts is an American timeshare resort company with its headquarters in Orlando, FL and has 29 locations across the United States.
            </p>
            <p>
              Glass partitions are perfect for smaller offices or to provide unique design features that set your space apart. No other wall treatment offers the same functionality, cleanliness, safety, durability, and refined look.
            </p>
          </div>
        }
      />
      <ActiveItemGallery
        items={galleryItems}
        galleryTitle="Project images:"
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
                        <FormWrapper
              modal
              buttonProps={{
                content: "Request A Quote",
                size: "tiny",
                icon: "file pdf outline",
              }}
            >
              <RequestQuote />
            </FormWrapper>
          </div>
        }
      />
      
    </PageLayout>
  )
}

export default LaminatedGlassOrlandoFlWestgateResorts